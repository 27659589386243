import {
  Component,
  forwardRef,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormControl
} from '@angular/forms';
import {ProductService} from 'tekkeys-common';
import {Observable} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true,
    },
  ],
})
export class NumberInputComponent
  implements OnInit, ControlValueAccessor, OnChanges {
  @Input() max;
  @Input() min = 1;
  @Input() isDisabled = false;
  @Input() maxForQuot;
  @Input() remainder = true;
  @Input() quantityP: number;
  @Input() productId: number;
  @Input() context: 'panier' | 'devis';
  valueInput:number;
  public numQuantity;
  quantityAffiche: Observable<number>;
  valueControl: FormControl = new FormControl(1);

  constructor(public productService: ProductService,
              private readonly router: Router,
              private readonly route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    // Charger la quantité sauvegardée depuis localStorage
    const savedQuantity = localStorage.getItem(`product-${this.productId}`);
  //  this.fullUrl = this.route.snapshot['_routerState'].url;
    if (savedQuantity) {
      // Assurez-vous que la valeur récupérée est correctement appliquée
      this.valueControl.setValue(+savedQuantity); // Convertir en nombre
    } else {
      // Si aucune quantité n'a été sauvegardée, initialise à une valeur par défaut
      this.valueControl.setValue(this.min); // ou une autre valeur par défaut
    }

    if (this.isDisabled) {
      this.valueControl.disable();
    }

    this.productService.getQuantity(this.productId).subscribe(
      value => {

        for (const item of value) {
          this.quantityAffiche = item.quantity;
          return  this.quantityAffiche;
        }
      },
      error => {
        console.error('Error fetching quantity:', error);
      }
    );
    this.valueControl.valueChanges.subscribe((value) => {
      if (value < this.min) {
        const clampedValue = Math.max(this.min, value);
        this.valueControl.setValue(clampedValue);
      } else {
        this.onChange(value);
      }
    });
    if ( !this.maxForQuot ){
      this.maxForQuot = Infinity;
    }


  }

  handleInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = parseInt(inputElement.value, 10);
    // @ts-ignore
    if (this.context === 'panier' && inputValue > this.max) {
      this.valueControl.setValue(this.max);
    } else {
      this.valueControl.setValue(inputValue);
    }
  }

  increment() {
    this.valueControl.setValue(this.valueControl.value + 1);
  }

  decrement() {
    this.valueControl.setValue(this.valueControl.value - 1);
  }

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  writeValue(value: number): void {
    this.valueControl.setValue(value);
  }


  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.valueControl.setValue(this.min);
  }
}
