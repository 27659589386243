import { Component, Inject} from '@angular/core';
import { Router} from '@angular/router';
import {OverlayService} from 'shared/services/overlay/overlay.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ClientDTO, ClientService, CompanyDTO, CompanyService} from "tekkeys-common";


@Component({
  selector: 'app-request-sent-dialog',
  templateUrl: './request-sent-dialog.component.html',
  styleUrls: ['./request-sent-dialog.component.scss'],
})
export class RequestSentDialogComponent  {
  client: ClientDTO;
  company: CompanyDTO;

  constructor(
    public dialogRef: MatDialogRef<RequestSentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private overlayService: OverlayService,
    private readonly clientService: ClientService,
    private readonly companyService: CompanyService,
    private readonly router: Router,

  ) {}
  closeDialog() {
    this.dialogRef.close();
    this.overlayService.closeDilog();
    this.clientService.refreshToken().subscribe(() => {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false; // Désactiver la réutilisation de la route
      this.router.onSameUrlNavigation = 'reload'; // Forcer le rechargement du composant
      this.router.navigate(['/profile/company']);
    });
  }



}
