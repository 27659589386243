import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, pluck, shareReplay, startWith, take } from 'rxjs/operators';
import { QuotationSuccessComponent } from 'shared/components/quotation-success/quotation-success.component';
import { AddressTransPipe } from 'shared/pipes/address-trans/address-trans.pipe';
import { AccountState } from 'store/account/account.state';
import { clearQuotation } from 'store/quotation/quotation.actions';
import { CartItemDTO, ClientAddressDTO, ClientService, CountryDTO, CountryService, QuotationService } from 'tekkeys-common';

import { ClientDTO } from '../../../../../projects/tekkeys-common/src/lib/DTO/client.dto';
import { QuotationDto } from '../../../../../projects/tekkeys-common/src/lib/DTO/quotation.dto';
import { AddAddresseDialogComponent } from '../../../profile/components/add-addresse-dialog/add-addresse-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

export interface DATAPRODUCT {
  products: CartItemDTO[];
  type?: string;
}

@Component({
  selector: 'app-new-quotation-dialog',
  templateUrl: './new-quotation-dialog.component.html',
  styleUrls: ['./new-quotation-dialog.component.scss'],
})
export class NewQuotationDialogComponent implements OnInit {
  finalStep = false;

  addAddressForm: UntypedFormGroup;
  clientAddresses: ClientAddressDTO[] = [];
  selectedAddress: ClientAddressDTO;

  countries: CountryDTO[];
  countries$: Observable<CountryDTO[]> = this.countryService
    .countries()
    .pipe(take(1));

  isCountry = true;

  account = this.store.select('account');

  message = new UntypedFormControl('', [Validators.required]);
  quantity: number;
  clientId: number;

  private PHONE_REGEX = /^(0|\+33|0033)[1-9](\s*?\d\s*?){8}$/;
  isVirtual: boolean;
  client: ClientDTO;
  quotation: QuotationDto;
  isDefault = false;

  constructor(
    private dialogRef: MatDialogRef<NewQuotationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DATAPRODUCT,
    private dialog: MatDialog,
    private store_: Store,
    private clientService: ClientService,
    private quotationService: QuotationService,
    private countryService: CountryService,
    private router: Router,
    private store: Store<{ account: AccountState }>,
  ) {}

  ngOnInit(): void {
    this.getClientAddresses();
    for (const cartItem of this.data.products) {
      if (cartItem.product.physical === true) {
        this.isVirtual = false;
        break;
      } else {
        this.isVirtual = true;
      }
    }
    this.message.markAllAsTouched();
  }

  getClientAddresses() {
    this.clientService.getClientAddresses().subscribe((res) => {
      this.clientAddresses = res;
      this.selectedAddress = this.finalStep
        ? this.clientAddresses[this.clientAddresses.length - 1]
        : this.clientAddresses.find((a, i) => a.isDefault);
      this.initForm();
    });
    this.clientAddresses.map(value => {
      if (value.isDefault) {
        this.isDefault = true;
      }
    });
  }

  initForm() {
    if (this.selectedAddress?.id){
      this.isDefault = true;
    }
    this.addAddressForm = new UntypedFormGroup({
      city: new UntypedFormControl(this.selectedAddress?.city, [
        Validators.required,
        Validators.pattern('^[a-zA-ZÀ-ÿ-. ]*$'),
      ]),
      country: new UntypedFormControl(
        this.selectedAddress?.country.name,
        Validators.required
      ),
      // addressTitle: new FormControl(this.selectedAddress?.addressTitle),
      streetName: new UntypedFormControl(
        this.selectedAddress?.streetName,
        Validators.required
      ),
      postalCode: new UntypedFormControl(this.selectedAddress?.postalCode, [
        Validators.required,
        Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$'),
      ]),
      cedex: new UntypedFormControl(
        this.selectedAddress?.cedex,
        Validators.pattern('^[0-9]{2}$')
      ),
      contactName: new UntypedFormControl(
        this.selectedAddress?.contactName,
        Validators.required
      ),
      contactPhone: new UntypedFormControl(
        this.selectedAddress?.contactPhone,
        Validators.required
      ),
    });

    this.countries$.subscribe((countries) => {
      this.countries = countries;
      this.countries$ = this.addAddressForm.get('country').valueChanges.pipe(
        startWith(''),
        map((value) => {
          this.isCountry = !!this.countries?.find(
            (country) =>
              country.name === this.addAddressForm.get('country').value
          );
          return this.countries?.filter((country) => {
            return (
              country.name.toLowerCase().includes(value.toLowerCase()) &&
              country.name !== value
            );
          });
        })
      );
    });
  }

  close() {
    this.dialogRef.close();
  }

  next() {
    if (this.isVirtual){
    this.finalStep = true;
  }
    if (!this.finalStep) {
      this.finalStep = true;
    }


    const deliveryAddress = this.isVirtual ? '' : new AddressTransPipe().transform({
      ...this.addAddressForm.value,
      country: this.countries.find(
        (c) => c.name === this.addAddressForm.get('country').value
      ),
    });

    this.store
      .select('account')
      .pipe(shareReplay(), pluck('account'))
      .subscribe((client) => (this.clientId = client?.id));

    if (this.data.type && this.data.type === 'devis') {
      // if we add a quotation from product-detail page
      // note: we may have other opened quotationItems
      const quotationItems = {
        clientId: this.clientId,
        address: this.isVirtual ? '' : deliveryAddress,
        message: this.message.value,
        productId: this.data.products[0].product.id,
        quantity: this.data.products[0].quantity,
        price: this.data.products[0].product.price,
      };
      this.quotationService.addNewQuotation(quotationItems).subscribe((res) => {
        this.clientService.getClient(res.clientId).subscribe((client) => {
          this.client = client;
          this.quotationCreatedSnackbar(res , this.client.mail);
          this.store_.dispatch(clearQuotation());
        });
      });
    } else {
      this.quotationService
        .submit({
          address: this.isVirtual ? '' : deliveryAddress + '#' + this.selectedAddress.code,
          message: this.message.value,
        })
        .subscribe((res) => {
          this.quotation = res;
          this.clientService.getClient(res.clientId).subscribe((client) => {
            this.client = client;
            this.quotationCreatedSnackbar(this.quotation , this.client?.mail);
            this.store_.dispatch(clearQuotation());
          });
        });
    }
    this.close();
  }

  quotationCreatedSnackbar(quotation: QuotationDto , clientMail: string) {
    this.dialog.open(QuotationSuccessComponent, {
      width: '380px',
      data: {quotationNum: quotation.id , email: clientMail}
  });

  }

  onAddAddressDialog() {
    const ref = this.dialog.open(AddAddresseDialogComponent, {
      data: null,
      maxWidth: '100%',
    });
    ref.afterClosed().subscribe(() => this.getClientAddresses());
  }
}
